<template>
    <div>
<v-stepper-content step="2">

          <v-card
            class="mb-8"
            :color="cardColor"
            >
            <v-container>
                <div class="text-center">
                    <b>Personal Details</b>
                </div>
            <v-row  class="mt-3">
                <v-col cols="12" md="3">
        <v-text-field
        label="Client Phone Number (Whatsapp):"  
        :color="color"
        type="number"
        >
        </v-text-field>
    </v-col>

    <v-col cols="12" md="3">
    <v-text-field 
    type="date" 
    label="Date of Birth:" 
    :color="color" >
    </v-text-field>
    </v-col>

    <v-col cols="12" md="3">
        <v-radio-group dense row>
        <span class="mr-3">Sex: </span>
        <v-radio
        label="Male"
        value="Male"
        color="green"
        ></v-radio>
        <v-radio
        label="Female"
        value="Female"
        color="green"
        ></v-radio>
    </v-radio-group>
    </v-col>

    <v-col cols="12" md="3">
        <v-select
        :items="educations"
        label="Education Status of Mother:"
        :color="color"
        item-color="success"
        ></v-select>
        </v-col>
         </v-row>

          <div class="mt-4 text-center"><b>Screening Details</b></div>

       <v-row class="mt-4">
   <v-col cols="12" md="6">
         <v-radio-group dense row>
    <span class="mr-2">Disclosure status to ward pre OTZ:</span>
    <v-radio
    label="Non-disclosure"
    value="Non-disclosure"
    color="green"
    ></v-radio>
    <v-radio
    label="Parial"
    value="Parial"
    color="green"
    ></v-radio> 
    <v-radio
    label="Full disclosure"
    value="Full disclosure"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>
<v-col cols="12" md="6">
    <v-radio-group dense row>
    <span class="mr-3">Missed appointment:  preOTZ </span>
    <v-radio
    label="None"
    value="none"
    color="green"
    ></v-radio>
    <v-radio
    label="1-2"
    value="one"
    color="green"
    ></v-radio> 
    <v-radio
    label="3-5"
    value="three"
    color="green"
    ></v-radio> 
    <v-radio
    label="5 or More"
    value="More"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>
 <v-col cols="12" md="6">
    <v-radio-group dense row>
    <span class="mr-2">Missed appointment postOTZ: </span>
    <v-radio
    label="None"
    value="none"
    color="green"
    ></v-radio>
    <v-radio
    label="1-2"
    value="one"
    color="green"
    ></v-radio> 
    <v-radio
    label="3-5"
    value="three"
    color="green"
    ></v-radio> 
    <v-radio
    label="5 or More"
    value="More"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>

<v-col cols="12" md="6">
    <v-radio-group dense row>
    <span class="mr-3">Missed drugs pre OTZ: </span>
    <v-radio
    label="None"
    value="none"
    color="green"
    ></v-radio>
    <v-radio
    label="1-2"
    value="one"
    color="green"
    ></v-radio> 
    <v-radio
    label="3-5"
    value="three"
    color="green"
    ></v-radio> 
    <v-radio
    label="5 or More"
    value="More"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>

<v-col cols="12" md="6">
    <v-radio-group dense row>
    <span class="mr-3">Missed drugs post OTZ :</span>
    <v-radio
    label="None"
    value="none"
    color="green"
    ></v-radio>
    <v-radio
    label="1-2"
    value="one"
    color="green"
    ></v-radio> 
    <v-radio
    label="3-5"
    value="three"
    color="green"
    ></v-radio> 
    <v-radio
    label="5 or More"
    value="More"
    color="green"
    ></v-radio> 
</v-radio-group>
</v-col>

<v-col cols="12" md="3">
    <v-text-field
    type="date"
    label="Last viral load pre OTZ:"
    :color="color"
    >
  </v-text-field>
</v-col>
<v-col cols="12" md="3">
    <v-text-field
    type="date"
    label="Last viral load post OTZ:"
    :color="color"
    >
  </v-text-field>
</v-col>
<v-row>
    <v-col cols="12">
    <b>HAART Regimen preOTZ</b>
    </v-col>
<v-col cols="12" md="3">
    <v-text-field
    type="date"
    label="HAART Regimen preOTZ(date commenced):"
    :color="color"
    >
  </v-text-field>
</v-col>
<v-col cols="12" md="4">
   <span><b>Anthropoimetry preOTZ: </b> </span> 
    <v-text-field
    label="weight (kg):"
    :color="color"
    >  
    </v-text-field>
    <v-text-field
    label="height (ht)"
    :color="color"
    >
    </v-text-field>
</v-col>
<v-col cols="12" md="4">
   <span><b>Anthropoimetry post OTZ: </b> </span> 
    <v-text-field
    label="weight (kg):"
    :color="color"
    >  
    </v-text-field>
    <v-text-field
    label="height (ht)"
    :color="color"
    >
    </v-text-field>
    <v-text-field
    label="BMI"
    :color="color"
    >
    </v-text-field>
</v-col>
</v-row>
<v-row>
    <v-col cols="12">
    <b>HAART Regimen post OTZ</b>
    </v-col>
<v-col cols="12" md="3">
    <v-text-field
    type="date"
    label="HAART Regimen post OTZ(date commenced):"
    :color="color"
    >
  </v-text-field>
</v-col>
<v-col cols="12" md="4">
   <span><b>Anthropoimetry preOTZ: </b> </span> 
    <v-text-field
    label="weight (kg):"
    :color="color"
    >  
    </v-text-field>
    <v-text-field
    label="height (ht)"
    :color="color"
    >
    </v-text-field>
</v-col>
<v-col cols="12" md="4">
   <span><b>Anthropoimetry post OTZ: </b> </span> 
    <v-text-field
    label="weight (kg):"
    :color="color"
    >  
    </v-text-field>
    <v-text-field
    label="height (ht)"
    :color="color"
    >
    </v-text-field>
    <v-text-field
    label="BMI"
    :color="color"
    >
    </v-text-field>
</v-col>
</v-row>
<v-col cols="12" md="6">
    <v-text-field
    type="number"
    label="Total attendance of meetings of OTZ club before postOTZ assessment:"
    :color="color"
    >
  </v-text-field>
</v-col>
            </v-row>
            </v-container>
          </v-card>
           <v-card-actions>
            <v-btn
            color="error"
            small
            >
              Previous
            </v-btn>
            <v-spacer></v-spacer>
          <v-btn
            small
              color="green lighten-1">
              Save Changes
            </v-btn>
          </v-card-actions> 
</v-stepper-content>
    </div>
</template>

<script>
    export default {

data() {
    return {
         color: '#024202',
         cardColor: '#f0f7f0',
         educations: [{value:'', text:'Select...', disabled: true},'Tertiary','Secondary',  'Primary', 'Koranic','None',],

    dform:{
        client:{},
    }
    }
},
        
    }
</script>

<style lang="scss" scoped>

</style>